.auth_forgot_password_main_container {
  width: 50%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.auth_forgot_password_main_container_mobile {
  width: 100%;
  padding: 10px;
  margin-bottom: 140px;
}

.auth_forgot_password_back_text_container {
  cursor: pointer;
}

.auth_forgot_password_back_text {
  font-size: 1em;
  color: var(--light-grey);
}


.auth_forgot_password_back_icon {
  color: var(--light-grey);
}
