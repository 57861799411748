.signin_main_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 100%;
  z-index: 0;
  transition: width 0.5s ease, opacity 0.2s ease;
  opacity: 0;
}

.signin_main_container_opacity {
  opacity: 1;
  width: 70%;
}

.signin_forgot_password_text {
  text-decoration: underline;
  cursor: pointer;
}

.signin_container {
  width: 50%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.signin_description {
  text-align: center;
}

/* MOBILE CSS */

.signin_main_container_mobile {
  width: 100%;
}

.signin_container_mobile {
  width: 100%;
  padding: 10px;
  margin-bottom: 140px;
}

.signin_description_mobile {
  font-size: 14px;
}
