.signup_main_container {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: width 0.5s ease, opacity 0.2s ease;
  opacity: 0;
}

.signup_main_container_opacity {
  opacity: 1;
  width: 70%;
}

.signup_container {
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 450px;
  padding: 10px;
  gap: 10px;
}

.signup_description {
  text-align: center;
}

/* MOBILE CSS */

.signup_main_container_mobile {
  width: 100%;
  padding: 10px;
}

.signup_container_mobile {
  width: 100%;
  padding: 10px;
  margin-top: 140px;
}

.signup_description_mobile {
  font-size: 14px;
}
