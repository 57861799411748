.auth_reset_password_input_container {
  padding: 10px;
  border-radius: 10px;
  max-width: 300px;
}

.auth_reset_password_title {
  color: var(--primary-bg-color);
  text-align: center;
  margin: 0;
  font-size: 40px;
}

.auth_reset_password_text {
  text-align: center;
  color: #6c6c6c;
}
