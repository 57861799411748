* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

:root {
  --primary-bg-color: #6c39ff !important;
  --secondary-bg-color: #d942ff !important;
  --primary-icon-color: #6c39ff !important;
  --active-sidebar-color: #fdf9fe !important;
  --green: #d942ff !important;
  --red: #6c39ff !important;

  /* Error Color */
  --primary-error-color: #b71c1c;
}

#root,
body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fdf9fe;
}

h1 {
  font-size: 42px;
  margin: 0;
}

.overflow-y {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
  transition: 0.3s;
  color: #393e46;
}

.cursor-pointer:hover {
  opacity: 0.8;
}

.cursor-pointer:active {
  opacity: 0.6;
}

.gap-10 {
  gap: 10px;
}

.justify-between {
  justify-content: space-between;
}
