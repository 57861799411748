.gauge-v2 {
  color: #000;
  line-height: 18px;
  box-sizing: border-box;
  display: block;
  font-size: 12px;
  position: relative;
  user-select: none;
  height: 30px;
}

.gauge-v2-rect {
  color: #000;
  line-height: 18px;
  font-size: 12px;
  user-select: none;
  box-sizing: border-box;
  display: block;
  outline: none !important;
  overflow: hidden;
  position: relative;
  top: 10px;
  height: 9px;
  border-radius: 5px;
  background-color: rgba(108, 57, 255, 0.2);
}

.gauge-v2-bar {
  color: #000;
  line-height: 18px;
  font-size: 12px;
  user-select: none;
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 10px;
  height: 9px;
  background-color: var(--primary-bg-color);
  left: 0px;
  border-radius: 5px;
}

.gauge-v2-circle {
  line-height: 18px;
  user-select: none;
  box-sizing: border-box;
  cursor: pointer;
  touch-action: pan-y;
  z-index: 1;
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 5px;
  font-size: 10px;
  height: 20px;
  width: 20px;
  background-color: var(--primary-bg-color);
  border: none;
  color: var(--primary-bg-color);
}

.gauge-v2-circle:after {
  background-color: rgba(108, 57, 255, 0.3);
  border-radius: 50%;
  content: "";
  display: block;
  height: 30px;
  left: auto;
  position: absolute;
  top: auto;
  width: 30px;
  z-index: 1;
}
