.general_main_container {
  position: relative;
  width: 100dvw;
  height: 100dvh;
}

.general_left_top {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  margin: 40px;
  cursor: pointer;
}
