.global_text_error {
  color: var(--primary-error-color);
}

.global_title {
  text-align: center;
  color: var(--primary-bg-color);
}

/* GLOBAL MOBILE CSS */

.global_title_mobile {
  font-size: 36px;
}
