.auth {
  position: relative;
  overflow: hidden;
  display: flex;
  height: 100%;
}

.auth_filter_main_container {
  position: absolute;
  padding: 10px;
  background-image: url("../../images/filter/background_filter.jpg");
  background-size: cover;
  z-index: 10;
  height: 100%;
  width: 30%;
  top: 0;
  transition: transform 0.9s ease;
}

.auth_filter_left {
  transform: translateX(calc(100vw - 100%));
}

.auth_filter_right {
  transform: translateX(0%);
}
.auth_left_top {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  margin: 40px;
  cursor: pointer;
}

/* MOBILE CSS */

.auth_mobile {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.auth_filter_main_container_mobile {
  position: absolute;
  padding: 10px;
  background-image: url("../../images/filter/background_filter.jpg");
  background-size: cover;
  z-index: 10;
  width: 100%;
  height: 140px;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 40px;
  transition: transform 0.9s ease;
}

.auth_filter_main_container_mobile_top {
  transform: translateY(calc((-100dvh / 2) + 70px));
}

.auth_filter_main_container_mobile_bottom {
  transform: translateY(calc((100dvh / 2) - 70px));
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.auth_filter_mobile_texte {
  color: var(--white);
  margin: 0;
  text-align: center;
  font-size: 10px;
}
